import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Wrapper = styled.div`
  height: 350px;
  margin: 15px;
  border-radius: 20px;
  text-align: center;
  border: 1px solid rgba(1, 0, 0, 1);
  background-repeat: no-repeat;
  background-image: ${props =>
    props.src
      ? `url(
          ${props.src}
        )`
      : ""};
  background-position: center;
  background-size: cover;
  transform: scale(1.05);
  transition-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 275px;

  &:hover {
    transform: scale(1.2);
    transition-duration: 0.5s;
  }

  a {
    text-decoration: none;
  }
`

const Title = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: 24px;
  font-weight: 700;
`

const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: white;
  font-style: italic;
`

const ProjectDisplay = ({
  title,
  date,
  link,
  imageSrc = "https://images.unsplash.com/photo-1513569771920-c9e1d31714af?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGJsYWNrfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60",
}) => {
  return (
    <Wrapper src={imageSrc} href={link}>
      <Link to={link} itemProp="url">
        <Title>{title}</Title>
        <Date>{date}</Date>
      </Link>
    </Wrapper>
  )
}

export default ProjectDisplay
