import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Post from "../components/post"
import styled from "@emotion/styled"
import ProjectDisplay from "../components/projectDisplay"

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Sidebar = styled.div`
  display: flex;
`

const Posts = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const portfolioPosts = posts.filter(
    post => post.fields.slug.split("/")[1] == "portfolio"
  )

  if (portfolioPosts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>This will be filled out shortly!</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <Content>
        <Posts>
          {portfolioPosts.map(post => {
            return (
              <ProjectDisplay
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                link={post.fields.slug}
                imageSrc={post.frontmatter.imageSrc || undefined}
              />
            )
          })}
        </Posts>
      </Content>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          imageSrc
        }
      }
    }
  }
`
